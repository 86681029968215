.pricing {
  &__table {
    width: 100%;
    //padding: ;
    table {
      width: 100%;
      thead {
        td {
          font-weight: 500;
          white-space: nowrap;
          //padding: 25px;
        }
      }
      tbody {
        background: #fff;
      }
      td {
        padding: 12px 25px;
      }
    }

    &__heading {
      background: #e1e1e1;
      h4 {
        margin-bottom: 0;
      }
    }

    &__item {
      &__estimated-revenue {
        background: #E1F6F3;
        text-align: center;
      }
      &__delivery-price {
        text-align: center;
      }
      &__retail-price {
        text-align: center;
        .form-group {
          margin: 0 5px;
          .invalid-feedback {
            display: none;
          }
        }
        &__input {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .form-control {
          width: 70px;
          text-align: center;
        }

        button {
          width: 20px;
          height: 20px;
          background: #F5F6F8;
          border: unset;
          border-radius: 2px;
          color: #BABEC2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
