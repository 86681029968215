.canvas-btn-toolbar {
  padding: 10px 0;
  display: flex;
  align-items: center;
  width: 40px;
  .btn {
    border-radius: 5px;
    padding: 5px;
    width: 100%;
  }
  .btn + .btn {
    margin-top: 10px;
  }
}

.canvas-editor {
  padding: 0 5px;
  height: 100%;
}

.canvas-setting-bar {
  padding-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .accordion-header {
    button {
      background: #fff;
    }
  }

  &__export {
    margin-top: 40px;
    //margin-top: auto;
    //margin-bottom: 30px;
  }
}

.canvas-dropzone {
  //position: absolute;
  //height: 100%;
  //width: 100%;
  .dropzone {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    .dz-message {
      max-width: 300px;
    }
  }
}

.canvas-color-picker {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  &__color {
    width: 30px;
    height: 30px;
    border: 1px solid #000;
  }
  &__value {
    margin-top: 10px;
    font-size: 13px;
  }

  margin-bottom: 30px;
}

.canvas-image-widget {
  &__item {
    img {
      max-height: 100px;
    }
  }
}
