$color-primary:       #425563;
$color-secondary:     #DBDBD3;
$color-success:       #8ae8db;
$color-info:          #BDD6E6;
$gray-400:            #E1E1E2;


@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
/* eng font */
@import url("https://fonts.googleapis.com/css2?family=Fira+Mono&family=Hurricane&family=League+Gothic&family=Roboto+Slab:wght@100&display=swap");
/* korean font */
@import url("https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Gamja+Flower&family=Jua&family=Nanum+Gothic&family=Noto+Sans+KR:wght@100&family=Noto+Serif+KR:wght@300&display=swap");
/* japanese font */
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@300&family=Noto+Sans+JP:wght@300&family=Noto+Serif+JP&family=Rampart+One&family=Yuji+Hentaigana+Akebono&family=Zen+Kurenaido&display=swap");
/* simplified chinese font */
@import url("https://fonts.googleapis.com/css2?family=Ma+Shan+Zheng&family=Noto+Serif+SC&family=ZCOOL+KuaiLe&display=swap");
/* original chinese font */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300&family=Noto+Serif+TC:wght@300&display=swap");
/* vietnamese font */
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Dancing+Script:wght@500&family=Inconsolata:wght@300&family=Open+Sans&family=Playfair+Display:ital@1&family=Roboto+Mono:wght@300&display=swap");


@import "mixins/global-mixins";
@import "components";

//html {
//  min-height: 100%;
//  height: 100%;
//}
//
body, html, #root {
  min-height: 100%;
  height: 100%;
}

#layout-wrapper {
  position: relative;
}

.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content {
  display: flex;
  flex-direction: column;
}

.page-content, .page-content > .container-fluid {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
//
//.page-content, .page-content > .container-fluid {
//  min-height: 100%;
//  height: 100%;
//}

.btn-secondary {
  color: $color-primary!important;
}


.btn-success, .btn-warning {
  color: #fff;
}

.btn {
  border-radius: 25px;
  text-transform: uppercase;
  letter-spacing: 0.98px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 9px;
}

.header-btn-group {
  display: flex;
  align-items: center;
  .btn + .btn {
    margin-left: 20px;
  }

  .btn {
    height: 40px;
  }
}

.modal-header {
  border-bottom: 0;
  justify-content: center;
}

.search-wrapper {
  label {
    width: 100%;
  }
}

.modal-dialog {
  &.notification-modal {
    --bs-modal-width: 600px;
    .modal-content {
      background: #425563;
      color: #fff;
      border-color: #425563;
      border-radius: 20px;

      h4 {
        color: #fff;
        text-align: center;
      }

      p {
        text-align: center;
        color: #f0f092;
        font-size: 16px;
      }

      .modal-body {
        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
          padding-top: 20px;
          i {
            color: #FFCD8B;
            font-size: 80px
          }
        }
      }

      .modal-footer {
        border: 0;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 20px;
        button {
          background: unset;
          text-transform: unset;
          letter-spacing: unset;
          border: 0;
          color: #AEB6BC!important;
        }
      }
    }
  }
}

.modal-auto {
  --bs-modal-width: unset;
  width: auto!important;
  max-width: 90%;
  justify-content: center;
  .modal-content {
    min-width: 500px;
    width: unset;
  }
}

//#layout-wrapper {
//  min-height: 100vh;
//  display: flex;
//  flex-direction: column;
//  .main-content {
//    display: flex;
//    flex-direction: column;
//    height: 100%;
//    flex-grow: 1;
//  }
//}
