.order-details {
  &__design {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
      margin-top: 10px;
    }
    img {
      width: 180px;
    }
  }

  .delivery-data {
    .form-control {
      padding: 0.2rem 0.3rem;
    }

    tr {
      td {
        &:nth-child(1) {
          vertical-align: middle;
        }
      }
    }

    &__service {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
