.form-control {
  &:focus, &:hover {
    border-color: #425563;
  }
}

.input-group-text {
  transition: 0s;
}
.form-control {
  transition: 0s;
}

.input-group {

  &:hover, &.input-group--focus {
    .input-group-text {
      border-color: #425563;
      transition: 0s;
    }
    .form-control {
      border-color: #425563;
      transition: 0s;
    }
  }
}
