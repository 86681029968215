@import "title";
@import "overlay";

@import "product-card";
@import "pagination";
@import "product-row-items";
@import "products-carousel";
@import "phone-preview";
@import "file-upload";
@import "image-preview-with-reupload";
@import "mockup-preview";
@import "order-selection-bar";
@import "data-table";
@import "wizard-summary";
@import "product-detail";
@import "select-field";
@import "index-cards";
@import "category-card";
@import "wizard-steps";
@import "wizard-step";
@import "insert-address";
@import "image-gallery";
@import "radio-select";
@import "collapse";
@import "tree-view";
@import "chat";
@import "tooltip";
@import "form-control";
@import "order-details";
@import "order-management-table";
@import "introduction";
@import "dashboard";
@import "message";
@import "search-bar-with-buttons";
@import "link-info-card";
@import "auth-hero-block";
@import "integrations";
@import "product-wizard";
@import "product-listings";
@import "product-listings-table";
@import "pricing";
@import "mockup-generator";
@import "setup-pending-orders";
@import "mockup-order-setup";
@import "video-guide";

.required:after
{
  content:"*";
  color:red;
}

.wizard .steps .current a .number, .wizard .steps .current a:active .number, .wizard .steps .current a:hover .number {
  background-color: $color-primary;
  border-color: #fff;
}

.wizard .steps .current a, .wizard .steps .current a:active, .wizard .steps .current a:hover {
  background-color: #425563;
  color: #fff;
}

.wizard .steps .number {
  border-color: #628092;
  color: #628092;
}

//.wizard .steps > ul > li a {
//  background-color: #DBDBD3;
//}

.card-bg-highlight {
  background-color: #fef9f5;
}

.image-sm {
  width: 120px;
  height: 50px;
  object-fit: cover;
}

.image-md {
  max-height: 150px;
}

.bg-green {
    background-color: #5cb85c;
}

.tooltip-icon-wrapper {
  width: 14px;
  height: 14px;
  margin-left: 3px;

  &.tooltip-icon-wrapper--hoverable {
    .tooltip-icon {
      background: url("../../../assets/images/icons/tooltip-icon_base.png");
      background-size: contain;
    }
    &:hover {
      .tooltip-icon {
        background: url("../../../assets/images/icons/tooltip-icon.png");
        background-size: contain;
      }
    }
  }
}

.tooltip-icon {
  background: url("../../../assets/images/icons/tooltip-icon.png");
  background-size: contain;
  width: 14px;
  height: 14px;
  display: inline-flex;
  margin-bottom: -2px;
}

.location-icon {
  background: url("../../../assets/images/icons/location-icon.png");
  background-size: contain;
  width: 10px;
  height: 12px;
  min-width: 10px;
  min-height: 12px;
  display: inline-flex;
}

@media (max-width: 1400px) {
  //.navbar-brand-box {
  //  img {
  //    width: 100%;
  //  }
  //}
  .vertical-menu, .navbar-brand-box {
    width: 240px;
  }
  .footer {
    left: 240px;
  }
  .main-content {
    margin-left: 240px;
  }
}


.modal-body {
  &.modal-body--gray {
    background-color: #fcfcfc;
  }
}

.btn-success {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a4f1e6;
}

.filter.number-filter {
  label {
    margin-bottom: 0!important;
  }

  label + label {
    margin-left: 5px;
  }
}

.hide-arrow {
  .tooltip-arrow {
    display: none !important;
  }
}

.notification-item {
  background: unset;
}

.notification-item--read {
  opacity: 0.7;
}


.form-check {
  &.form-check--green {
    input {
      width: 20px;
      height: 20px;
      background-color: #fff;
      color: #fff;
      border-color: #8AE8DB!important;
      &:focus {
        box-shadow: unset;
      }
      &:checked {
        background-color: #8AE8DB;
        color: #fff;
      }

      margin-right: 10px;
      margin-top: -1px;
    }
  }
}
