.canvasAssetPreview {
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    z-index: 10;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.canvasAssetPreviewSizeLabel {
    position: absolute;
    top: 0px;
    left: 0px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    font-size: 14px;
    background: #fff;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: #fe0b0b;
}

.canvasAssetPreviewSizeLabel strong {
    color: #fe0b0b;
}

.canvasAssetPreview img {
    width: 100%;
    margin-top: 40px;
}
