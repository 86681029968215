.products-carousel {
  padding: 0 60px;
  position: relative;
  //margin: -10px;
  .react-multi-carousel-list {
    padding-left: 0;
    padding-right: 0;
  }
  .card {
    margin-bottom: 0;
    height: 100%;
    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
    .card-body {
      padding: 10px;
      h6 {
        margin-bottom: 0;
      }
    }
    @include action-trigger;
  }
  //.react-multi-carousel-item {
  //  margin: 10px;
  //}
  &__item {
    height: 100%;
    margin: 0 10px;
  }
  &__arrows {
    position: unset;
    &__btn {
      position: absolute;
      top: calc(50% - 21px);
      outline: 0;
      transition: all .5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      //background: rgba(0,0,0,0.5);
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
      &:before {
        font-size: 20px;
        color: #000;
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative;
      }
      &.products-carousel__arrows__btn--left {
        left: 10px;
        &:before {
          content: "\e824";
        }
      }
      &.products-carousel__arrows__btn--right {
        right: 10px;
        &:before {
          content: "\e825";
        }
      }
    }
  }
}
