.expand-cell-header {
  pointer-events: none;
}

.reset-expansion-style {
  padding: 0!important;
  border-bottom: 0 !important;
}

.react-bootstrap-table {
  table th {
    vertical-align: text-top;
  }
  //.expand-cell {}
  .expand-cell-header {
    max-width: 20px;
  }
  .selection-cell {
    width: 40px;
  }
}

// hack to remove animation from expandable row
.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  display: none;
  transition: none !important;
}

// hack to remove animation from expandable row
.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: none !important;
}

.react-boostrap-table-expandable-row-control {
  font-size: 11px;
  cursor: pointer;
  white-space: nowrap;
}


.order-management-table-expanded-row > div {
  background: #f8f9fa;
  padding: 20px;
}
