.font1em {
  font-size: 1em !important;
}

.font1rem {
  font-size: 1rem !important;
}

.fontHalf1em {
  font-size: 0.5em !important;
}

.font075em {
  font-size: 0.75em !important;
}
