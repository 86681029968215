.auto {
  overflow: auto;
}

.auto-y {
  overflow-y: auto;
}

.auto-x {
  overflow-x: auto;
}

.hide {
  overflow: hidden;
}
