.introjs-tooltip.introduction-tooltip {
  background-color: #3D5AEB;
  color: #fff;
  max-width: 600px;
  h1, a {
    color: #fff;
  }

  .introjs-helperNumberLayer {
    color: #fff;
  }

  .introjs-button {
    background-color: #fff;
    color: #3D5AEB;
    border-radius: 20px;
    border: unset;
    &.introjs-disabled {
      opacity: 0.5;
    }
  }

  .introjs-arrow.top {
    border-bottom-color: #3D5AEB
  }

  .introjs-arrow.left {
    border-right-color: #3D5AEB
  }
}
