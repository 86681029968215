.mockup-preview {
  margin-bottom: 20px;
  .card {
    margin-bottom: 0;
  }
  &__select {
    padding: 0 10px;
    .select-field {
      margin-bottom: 0!important;
    }
  }
  &__delete {
    position: absolute;
    top: 4px;
    right: 4px;
    cursor: pointer;
    transition: .3s;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #425563;
    color: #fff;
    padding: 4px;
    border-radius: 3px;
    &:hover {
      opacity: .8;
    }
  }
  &__image {
    //padding: 10px;
    //background: #f2f2f2;
    display: flex;
    //flex-grow: 1;
    //height: 100%;
  }
  &__text {
    text-align: center;
    padding: 10px;

    &__icon {
      i {
        font-size: 48px;
      }
    }
  }
  .card-img-top {
    max-height: 100px;
    height: 100px;
    object-fit: contain;
  }
  &.fit-image {
    .card-img-top {
      object-fit: cover;
      max-height: 100px;
    }
  }
  &__download {
    position: absolute;
    top:5px;
    right:5px;
    button {
      border-radius: 3px;
      padding: 0;
      width: 20px;
      height: 20px;
      background: #425563;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__order-cursor {
    position: absolute;
    top:5px;
    left:5px;
    display: flex;
    align-items: center;
    border: 1px solid #bdd6e6;
    border-radius: 3px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    transition: .3s;
    button {
      padding: 0;
      width: 17px;
      height: 17px;
      background: #425563;
      //border-radius: 3px;
      border: 0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s;
      &:hover {
        opacity: .8;
      }
    }
  }

  &:hover {
    .mockup-preview__order-cursor {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.mockup-list-preview  {
  .mockup-preview {
    margin-bottom: 0;

    &__image {
      align-items: flex-start;
      //height: 130px;
      justify-content: center;
    }
  }
}


.selected-designs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -10px;
  &__item {
    width: 25%;
    padding: 10px;
    &__inner {
      width: 100%;
      padding: 5px;
      border: 1px solid #f2f2f2;
      border-radius: 5px;
    }
    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        //width: 100%;
        height: 60px;
        width: 100%;
        object-fit: cover;
      }
    }
    &__title {
      text-align: center;
      font-size: 12px;
      margin-top: 5px;
    }
  }
}


.mockup-list {
  h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

}
