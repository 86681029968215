.search-bar-with-buttons {
  &__buttons {
    .btn {
      border-radius: 4px;
      border: 1px solid;
      text-transform: none;
      padding-left: 30px;
      padding-right: 30px;
      letter-spacing: unset;
      &.btn-secondary {
        background: #FFFFFF;
        border-color: #E7EBEF;
        color: #BABEC2!important;
      }

      &.btn-primary {
        background: #8AE8DB;
        border-color: transparent;
        color: #fff!important;
      }
    }
  }
}
