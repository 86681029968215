.image-gallery {
  display: flex;
  flex-direction: column;
  &__selected {
    background: #fff;
    border: 1px solid #f5f6f8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 100%;
    }
  }
  &__list {
    display: flex;
    width: 100%;
    margin: 15px -5px 0 -5px;
    &__item {
      //height: 138px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      img {
        width: 100%;
        max-height: 138px;
      }
      &.selected {
        border: 1px solid #425563;
      }
    }
  }
}
