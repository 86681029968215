.insert-address {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f5f6f8;
  padding: 20px;
  label {
    text-transform: uppercase;
    font-size: 14px;
  }
  &__input-wrapper {
    display: flex;
    &__addon {
      background-color: #fff;
      border: 1px solid #e1e1e2;
      border-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      .btn {
        white-space: nowrap;
      }
    }
    textarea {
      border: 1px solid #e1e1e2;
      border-right: 0;
      min-height: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:focus {
        border-color: #e1e1e2;
      }
    }
  }
}
