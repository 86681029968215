.select-field {
  min-height: 40px;
  .select2-selection__control {
    border: 1px solid #E1E1E2;
    &:hover, &.select2-selection__control--is-focused {
      border-color: #425563;
      box-shadow: none;
    }
  }
  .select2-selection__indicator-separator {
    display: none;
  }
  .select2-selection__option {
    &.select2-selection__option--is-focused {
      background-color: #CEF6F1;
    }
    &.select2-selection__option--is-selected {
      background-color: #aef3eb;
      color: #000;
    }
  }
  &.is-invalid {
    //label {
    //  color: #f56e50;
    //}
    .select2-selection__control {
      border-color: #f56e50;
    }
  }
}
