.product-row-items {
  margin-bottom: 20px;
  width: 100%;
  &__title {
    margin-bottom: 0;
  }
  h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
  &.product-row-items--inner-padding {
    padding: 0 60px;
  }
  &.product-row-items--is-invalid {
    //padding: 0 60px;
    .product-row-items__selector {
      .product-row-items__title {
        color: #f56e50;
      }
    }
  }
  &__list__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    i {
      margin-left: 5px;
      margin-top: -2px;
      font-size: 18px;
      color: #f56e50;
    }
  }
  &__error-text {
    padding: 0;
    //margin: 0;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 0;
  }
  &.product-row-items--lg-image {
    .card {
      img {
        object-fit: contain!important;
        height: 220px!important;
      }
    }
  }
  &.product-row-items--cover-image {
    .card {
      img {
        object-fit: cover!important;
      }
    }
  }
  &.product-row-items--without-preview {
    .product-row-items__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      max-height: unset;
      overflow-y: unset;
      //margin-left: -10px;
      //margin-right: -10px;
      &__item {
        flex-direction: column;
        //margin: 10px;
        //padding: 10px;
        border-radius: 0;
        //border: 2px solid transparent!important;
        transition: .3s;
        height: 100%;
        .card-body {
          display: flex;
          flex-direction: column;
          padding-bottom: 0;
          width: 100%;
        }
        .hoverable-image {
          opacity: 0;
          visibility: hidden;
          transition: .3s;
        }
        .card__image-container {
          width: 100%;
          border: var(--bs-border-width) var(--bs-border-style) $gray-400 !important;
          background: #ffffff;
          position: relative;
          //z-index: -2;
          //border-radius: 8px;
          > img {
            width: 100%;
            height: 100px;
            object-fit: cover;
          }
        }
        .hoverable-image {
          position: absolute;
          top: 0;
          left: 0;
        }
        .title-edit {
          background: unset;
          box-shadow: unset;
          border: none;
          cursor: pointer;
          width: 20px;
          height: 24px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include action-trigger;
          &.title-edit--save {
            background: #76e37f;
          }
        }
        &__title-container {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          .title-edit {
            margin-left: auto;
          }
          input {
            width: 100%;
            height: 25px;
            margin-right: 2px;
          }
          h5 {
            font-size: 16px;
            font-weight: 400;
          }
        }
        &__price {
          margin-bottom: 10px;
          .price-label {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            color: #425563;
          }
          .price-value {
            font-size: 12px;
            font-weight: 600;
            color: #8ae8db;
            margin-left: 10px;
          }
        }
        &__select-icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
          background: #8ae8db;
          color: #fff;
        }

        &__icon {
          position: absolute;
          width: 25px;
          height: 25px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0,0,0,.7);
          color: #fff;
          opacity: 0;
          visibility: hidden;
          transition: .3s;
          cursor: pointer;
          border: 0;
          box-shadow: none;
          right: 5px;
          z-index: 2;
          &:hover {
            background: rgba(0,0,0,.5);
          }
          &.product-row-items__list__item__icon--show-full {
            top: 5px;
          }

          &.product-row-items__list__item__icon--download {
            top: 35px;
          }

          &.product-row-items__list__item__icon--copy {
            top: 65px;
          }
          &.product-row-items__list__item__icon--delete {
            top: 95px;
          }
        }

        &:hover {
          .product-row-items__list__item__icon {
            opacity: 1;
            visibility: visible;
          }
          .hoverable-image {
            opacity: 1;
            visibility: visible;
          }
        }
        &.selected {
          .product-row-items__list__item__select-icon {
            display: flex;
          }
          border-color: #8ae8db!important;
          &:after {
            content: "";
            background-image: url("../../../assets/images/icons/check-icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px;
            background-color: #8ae8db;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 0;
            //background: #8ae8db;
          }
        }
        &:hover:not(.selected) {
          .card__image-container {
            border-color: #425563 !important;
          }
        }
        &.new-entity {
          .card__image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 222px;
            i {
              font-size: 60px;
            }
          }
        }
      }
    }
  }
  &__search {
    margin-bottom: 20px;
  }
  &__list {
    max-height: 380px;
    //min-height: 380px;
    overflow-y: auto;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      background: unset;
      &__select-icon {
        display: none;
      }
      &.selected {
        //background-color: $color-success;
        .card-body h6 {
          color: #fff;
        }
      }
      .card__image-container {
        position: relative;
        border: 2px solid transparent!important;
        > img {
          width: 50px;
          height: 50px;
          object-fit: cover;
        }
        &__tooltip {
          position: absolute;
          right: 10px;
          bottom: 10px;
          z-index: 100;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 20px;
            transition: .3s;
            &:hover {
              color: #ffcd8b;
            }
          }
        }
      }
      .card-body {
        padding: 10px;
        min-height: 40px;
        h6 {
          margin-bottom: 0;
        }
      }
      //@include action-trigger;
    }
  }
  &__preview {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        max-height: 290px;
        object-fit: contain;
      }
    }
  }
}


.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 600px;
    width: 100%;
  }
}
