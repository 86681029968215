.order-selection-bar {
  //background: #c2c2c2;
  padding: 2px 0;
  &__content {
    display: flex;
    align-items: center;
  }
  &__item {
    width: 65px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #b2aeae;
    color: #fff;
    font-weight: 600;
    margin: 3px;
    padding-top: 11px;
    box-shadow: none;
    outline: none;
    border-radius: 0;
    border: 0;
    text-align: center;
    position: relative;
    &:hover {
      .order-selection-bar__item__delete {
        opacity: 1;
      }
    }
    &:focus, &:active {
      opacity: 1 !important;
    }
    &.order-selection-bar__item--active {
      background: $color-primary;
    }
    //&.order-selection-bar__item--submitted {
    //  background: #5ba259;
    //}
    //&.order-selection-bar__item--draft {
    //  background: #5ba259;
    //}
    &.order-selection-bar__item--new {
      width: unset;
      font-size: 14px;
      background: $color-success;
      color: #495057!important;
      i {
        color: #575757;
        font-size: 20px;
      }
    }
    &__delete {
      background: #2e2e2e;
      height: 20px;
      width: 20px;
      opacity: 0;
      position: absolute;
      right: 0;
      top:0;
      transition: .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 12px;
      }
    }
    &__status {
      margin-left: 8px;
      margin-top: -1px;
      font-weight: 600;
      font-size: 18px;
      i {
        margin-top: 1px;
        color: $color-primary;
      }
    }
    @include action-trigger;
  }
}
