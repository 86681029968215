//
// _buttons.scss
//

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200 !important;
}

.btn-outline-light{
  color: $gray-900;
}

.btn-outline-dark, .btn-dark{
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover{
    color: $light;
  }
}

.btn.disabled {
  background-color: #e1e1e2!important;
  border: 0 !important;
  color: #babec2 !important
}

.btn.btn-gold {
  background: #ffcd8b;
  border-color: transparent;
  color: #fff!important;
  transition: .3s;
  &:hover {
    opacity: .8;
  }
}

// Button Border
//@each $color, $value in $theme-colors {
//  .btn-#{$color} {
//    border-bottom: 1.5px solid darken($value, 7.5%);
//  }
//}
