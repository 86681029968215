.shop-list {
  margin-top: 10px;
  overflow-x: hidden;

  &__items {
    > div {
      margin-bottom: 20px;
    }
  }
}

.integration-status-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  &__icon {
    i {
      font-size: 92px;
      color: #4fa64e;
    }
  }
}

.shop-item {
  background: #FFFFFF;
  border: 1px solid #E1E1E2;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  position: relative;
  height: 100%;

  &__name {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }

  &__controls {
    position: absolute;
    right: 5px;
    top: 5px;
    &__btn {
      background: unset;
      box-shadow: unset;
      outline: unset;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E1E1E2;
      border-radius: 3px;
      opacity: 0.6;
      transition: .3s;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__logo {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 38px;
      margin-right: 6px;
      margin-top: -9px;
    }
    img {
      width: 100%;
      height: 40px;
    }
  }

  &__currency {
    font-size: 12px;
    color: #BABEC2;
    margin-top: 24px;
  }

  &__copyright {
    font-size: 12px;
    color: #BABEC2;
    margin-top: 12px;
    text-align: center;
    transition: .3s;
  }

  &:hover {
    .shop-item__copyright {
      color: var(--bs-body-color);
    }
  }

  &__copyright + &__actions {
    margin-top: 12px;
  }

  &__actions {
    margin-top: 36px;
  }

  &__selected-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: #8AE8DB;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
