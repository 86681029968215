.pagination {
  .page-link {
    transition: unset;
  }
  .break {
    width: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
