.data-table {
  table {
    tr {
      th {
        .filter-label {
          display: block;
          margin-bottom: 0;
        }
      }
      td {
        img {
          width: 70px;
          height: 30px;
          object-fit: cover;
        }
      }
    }
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}
