.tree-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  .search {
    width: 100%;
  }
  ul {
    width: 100%;
    padding: 0;
    list-style: none;
    overflow-y: auto;
    max-height: 470px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px -17px;

    li {
      //width: 25%;
      max-width: 120px;
      min-width: 120px;
      padding: 0 17px;
      .tree-node-group {
        display: none;
      }
      .tree-node__image {
        position: relative;
        -webkit-mask-image: url("../../../assets/images/icons/mockup-folder.png");
        -webkit-mask-size: contain;
        background-color: #E1E1E2;
        background-repeat: no-repeat;
        background-position: center;
        width: 90px;
        height: 72px;
      }

      .tree-node {
        display: flex;
        flex-direction: column;
        padding-top: 3px;
        padding-bottom: 3px;
        cursor: pointer;
        display: flex;
        &.tree-node--selected {
          //background: #cbcbcb;
          .tree-node__image {
            background-color: #8ae8db;
          }
          .tree-view__name {
            font-weight: 600;
          }
        }
        i {
          margin-right: 3px;
        }

        &:hover {
          .tree-view__delete {
            opacity: 1;
          }
        }
      }
    }
  }

  &__folder-preview {
    position: absolute;
    height: 78%;
    width: 94%;
    bottom: 4px;
    left: 3px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  &__name {
    //position: absolute;
    //bottom: -23px;
    margin-top: 5px;
  }

  &__delete {
    margin-left: auto;
    position: absolute;
    display: inline-flex;
    cursor: pointer;
    background: unset;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 0;
    outline: 0;
    box-shadow: none;
    transition: .3s;
    bottom: 5px;
    right: 5px;
    background: #FF6E91;
    color: #fff;
    width: 20px;
    height: 20px;
    opacity: 0;
    i {
      margin-right: 0!important;
    }
  }

  .tree-node__branch {
    display: flex;
  }
}
