.asset-preview-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  align-items: center;
  justify-content: center;
  .asset-cropper-container {
    .cropper-canvas {
      img {
        border: 3px dashed #4de7db
      }
    }
    .cropper-view-box {
      img {
        border: 3px dashed #4de7db
      }
    }
  }
  .asset-preview__dropzone {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .dropzone {
    border: unset;
    height: 100%;
  }
  &__button {
    margin-top: 20px;
  }

  .asset-preview-controls {
    display: flex;
    margin-top: 20px;
    &__item {
      //width: 20px;
      //height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
      border: 1px solid $color-primary;
      i {
        font-size: 20px;
      }
      @include action-trigger;
        //&:last-child {
        //    margin-right: 0;
        //}
    }
  }
}

.asset-preview {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  left: 0;
  pointer-events: none;
  //background: red;

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {

    }
    img {
      //width: 290px;
      //height: 600px;
      //border-radius: 50px;
    }
  }
  //&__base {
  //  height: 600px;
  //  width: 290px;
  //  background: transparent;
  //  border-radius: 40px;
  //  border: 2px solid #000;
  //  position: relative;
  //  outline: solid 2px rgba(243,243,243,.56);
  //  outline-offset: -2px;
  //}
  //&__addon {
  //  position: absolute;
  //  height: 120px;
  //  width: 120px;
  //  top: 10px;
  //  left: 10px;
  //  border: 2px solid #000;
  //  background: #fff;
  //  border-radius: 40px;
  //}
  //&__printfile {
  //  position: absolute;
  //  height: 100%;
  //  width: 100%;
  //  z-index: -1;
  //  img {
  //    width: 100%;
  //    height: 100%;
  //    object-fit: cover;
  //    border-radius: 35px;
  //  }
  //}
}
