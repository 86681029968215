.product-card {
  cursor: pointer;
  background: #f7f7f7;
  margin: 0;
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  .card-body {
    margin: 0;
    display: none;
  }
  &__title {
    //background: gray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    padding: 10px 0 ;
    color: #000;
    h5 {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  //.card {
  //  border: 0;
  //}
  //h5 {
  //  display: none;
  //}
  @include action-trigger;
}
