.image-preview-with-reupload {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #E1E1E2;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    height: 100%;
  }

  &__title {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  &__image {
    img {
      width: 150px;
    }
  }
  &__upload {
    margin-top: 10px;
  }

  &.image-preview-with-reupload--error {
    .image-preview-with-reupload__inner {
      border-color: #f56e50;
    }
  }

}

.error-message {
  color: #f56e50;
  font-size: 12px;
}

.uploaded-image-list {
  &__title {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  &__images {
   /* display: flex;
    flex-wrap: wrap;*/
    //margin-bottom: 20px;
    width: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #E1E1E2;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
  }

  &.uploaded-image-list--error {
    .uploaded-image-list__inner {
      border-color: #f56e50;
    }
  }

  &__image-wrapper {
    margin-bottom: 15px;
  }

  &__image {
    border: 1px solid #E1E1E2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    height: 100%;
    img {
      width: 100%;
      max-width: 150px;
    }

    button {
      position: absolute;
      top:0;
      right: 0;
      padding: 0;
      width: 25px;
      height: 25px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      //background:
    }
  }
}
