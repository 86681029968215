.auth-hero {
  background: url("../../../assets/images/login_BG.png") no-repeat;
  background-size: cover;
  background-position: bottom;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 80px 50px 150px 150px;
  &__description {
    h2 {
      text-transform: uppercase;
      color: #425563;
      font-size: 54px;
      line-height: 54px;
      font-weight: bold;
      margin-bottom: 5px;
      font-family: 'Epilogue', sans-serif;
    }
    h3 {
      color: #425563;
      font-size: 38px;
      font-weight: 300;
      font-family: 'Epilogue', sans-serif;
    }
  }
  &__list {
    margin-top: 47px;
    h4 {
      color: #425563;
      font-size: 26px;
    }
    ul {
      margin-top: 32px;
      padding-left: 0;
      list-style: none;
      li {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}
