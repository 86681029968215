.listing-group {
  .listing-group__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
  }

  .listing-group__item {
    padding: 0 20px;
    margin-bottom: 10px;
    border-right: 1px solid #000;
    display: flex;
    align-items: center;

    &.listing-group__item--button {
      border-right: 0;
    }

    &__checkbox {
      //input {
      //  width: 20px;
      //  height: 20px;
      //  background-color: #fff;
      //  color: #fff;
      //  border-color: #8AE8DB!important;
      //  &:focus {
      //    box-shadow: unset;
      //  }
      //  &:checked {
      //    background-color: #8AE8DB;
      //    color: #fff;
      //  }
      //}
      &.listing-group__item__checkbox--hide {
        .form-check {
          padding-left: 0;
        }

        .listing-group__item__action {
          margin-left: 5px;
        }
      }
    }

    &__label {
      margin-left: 8px;
      margin-top: 3px;
      width: 100%;

      &__title {
        color: #425563;
        font-weight: 600;
        font-size: 16px;
        max-width: 140px;
        //white-space: nowrap;
      }

      &__models {
        margin-top: 2px;
        font-weight: 400;
      }
    }

    &__action {
      margin-left: 28px;
      margin-top: -6px;

      button {
        background: unset;
        box-shadow: unset;
        border: unset;
        outline: unset;
        color: #8AE8DB;
        text-decoration: underline;
      }
    }

    &__button {
      background: #425563;
      border-radius: 30px;
      padding: 13px;
      display: flex;
      align-items: center;
      color: #fff;
      text-transform: uppercase;
      height: 60px;
      border: 0;
      transition: .3s;

      &:hover {
        opacity: .7;
      }

      span {
        &:nth-child(1) {
          margin-right: 15px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          background: #8AE8DB;
          color: #425563;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 28px;
        }
      }
    }

    &__inner {
      position: relative;
    }

    &__delete {
      position: absolute;
      top: 4px;
      right: 0;
      cursor: pointer;
      transition: .3s;
      background: unset;
      border: unset;
      padding: 0;

      &:hover {
        opacity: .8;
      }
    }
  }
}

.listing-tabs {
  .nav-tabs {
    --bs-nav-tabs-border-width: 1px;
    border: var(--bs-nav-tabs-border-width) solid #0000;
    border-radius: 10px 10px 0 0;
    --bs-nav-tabs-link-hover-border-color: #eff2f7 #eff2f7 #e9ebec;
    --bs-nav-tabs-link-active-color: #495057;
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: #e9ebec #e9ebec #fff;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);


    &.error {
      color: #e7040f;
    }

  }

  .nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #9599ad;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  .nav-link {
    background: #fff;
    border: 1px solid #eff2f7;
    border-radius: 10px 10px 0 0;
    margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
    padding: 6px 28px;
    color: #425563;

    &.active {
      background: #425563;
      color: #fff;
      font-weight: 600;
    }
    &.error {
      color: #e7040f;
    }
  }
}


.listing-form {
  &__header {
    border-bottom: 1px solid #425563;
    display: flex;
    align-items: center;

    &__title {
      display: inline-flex;
      background: #425563;
      border-radius: 10px 10px 0 0;
      color: #fff;
      padding: 6px 28px;

      &__text {
        font-weight: 600;
      }

      .separator {
        margin: 0 10px
      }

      &__models {
        font-weight: 400;
      }
    }

    &__delete {
      margin-left: 10px;
      background: unset;
      box-shadow: unset;
      outline: unset;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #E1E1E2;
      border-radius: 3px;
      opacity: 0.6;
      transition: .3s;

      &:hover {
        opacity: 1;
      }
    }

    &__tabs {
      margin-left: 10px;

      button {
        background: unset;
        border: 0;
        padding: 6px 20px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

        &.active {
          background: #d3d3d3;
        }
      }
    }
  }

  &__body {
    padding-top: 24px;
  }

  &__form-group {
    &__input {
      display: flex;
      //align-items: center;
      width: 100%;

      .form-group {
        width: 100%;
      }

      button {
        margin-left: 10px;
        height: 38px;
        margin-top: 28px;
      }
    }

    &__bottom {
      margin-top: 10px;
    }
  }

  &__tag {
    background: #425563;
    border-radius: 20px;
    padding: 6px 12px;
    color: #fff;
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;

    &__remove {
      border: 0;
      padding: 0;
      background: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin-left: 10px;
    }
  }

  &__template-selector {
    display: flex;
    align-items: center;

    .select-field {
      width: 180px;
    }

    &__actions {
      margin-top: 12px;
      margin-left: 10px;

      .btn + .btn {
        margin-left: 5px;
      }
    }
  }

}


.listing-group-edit-form {
  &__item {
    padding: 20px 0;
    border-bottom: 1px solid #425563;

    &__title {

    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 10px -2px 0 -2px;

      &__item {
        margin: 0 2px
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;

    &__clear-all {
      margin-top: -12px;
      margin-left: 50px;
      color: #BABEC2;
      background: unset;
      border: unset;
      outline: unset;
      padding: 0;
    }
  }
}

.listing-group-modal-body {
  padding: 10px 0;
  max-height: 600px;
  overflow-y: auto;
}
