.category-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f7f7f7;
  &__image {
    height: 90px;
    margin-left: auto;
  }
  &__title {
    padding: 0 60px 0 20px;
    h5 {
      margin: 0;
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
    }
  }
}
