.mockup-type-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__card {
    padding: 20px;
    border-radius: 20px;
    max-width: 400px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    &.green {
      border: 8px solid #8AE8DB;

      .btn {
        background: #8AE8DB !important;
      }
    }
    &.purple {
      border: 8px solid #B7BBF1;

      .btn {
        background: #B7BBF1 !important;
      }
    }

    &__image {
      img {
        border-radius: 30px;
        width: 100%;
        height: 160px;
        object-fit: cover;
      }
    }
    &__body {
      margin-top: 23px;
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      h5 {
        text-align: center;
        font-size: 26px;
        font-weight: 600;
      }

      p {
        text-align: center;
        font-size: 16px;
        color: #425563;
      }

      .btn {
        margin-top: auto;
        width: 100%;
        height: 60px;
        font-size: 20px;
        font-weight: 600;
        color: #fff!important;
        border-radius: 30px;
      }
    }
  }

  &__card + &__card {
    margin-left: 40px;
  }
}
