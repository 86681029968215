.dashboard-introduction {
  width: 100%;
  display: flex;
  &__left-content {
    width: 50%;
    background: transparent linear-gradient(316deg, #B7BBF1 0%, #8AE8DB 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px 0px 0px 10px;
    padding: 60px;
    display: flex;
    flex-direction: column;
  }

  &__user-info {
    display: flex;
    align-items: center;
    &__avatar {
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    &__content {
      margin-left: 25px;
      color: #fff;
      h3 {
        font-size: 26px;
        color: #fff;
        font-weight: 500;
      }
      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }

  &__right-content {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    margin-top: 30px;

    h1 {
      color: #fff;
    }

    p {
      color: #fff
    }
  }

  &__button {
    margin-top: 30px;
    width: 100%;
    button {
      width: 100%;
    }
  }
}

.statistics-widget {
  margin-top: 20px;
  background: #F9F9F9;
  border-radius: 10px;
  padding: 20px;
  &__header {
    //display: flex;
    //align-items: center;

    &__selector {
      .select-field {
        margin-bottom: 0!important;
      }
    }

    &__button-group {
      .btn + .btn {
        margin-left: 10px;
      }

      .btn {
        height: 38px;
        border-radius: 4px;
        border: 1px solid #E7EBEF;
        background: #FFFFFF;
        color: #BABEC2;
        &.btn-success {
          border-color: transparent;
          background: #8AE8DB;
          color: #fff;
        }
        &:hover {
          border-color: #CCD0D4;
        }
      }
    }
  }
}

.holidays-widget {
  display: flex;
  min-height: 180px;
  &__left {
    padding: 20px;
    width: 50%;
    border-radius: 10px 0px 0px 10px;
    background: transparent linear-gradient(294deg, #B7BBF1 0%, #8AE8DB 100%) 0% 0%;

    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    .btn {
      margin-top: 15px;
    }
  }

  &__btn {
    display: flex;
    //justify-content: center;
    width: 100%;
  }

  &__right {
    width: 50%;
    img {
      border-radius: 0px 10px 10px 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: 20px;
    color: #fff;
  }

  &__list {
    margin-top: 30px;
    //display: flex;
    //flex-direction: column;
    width: 100%;
    &__item {
      width: 100%;
      //display: flex;
      //align-items: center;
      &__date, &__name {
        color: #fff;
      }

      &__date {
        width: 50%;
        i {
          margin-right: 9px;
        }
      }

    }
    &___item + &__item {
      margin-top: 10px;
    }
  }
}

.help-widget {
  background: #E1E1E2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //todo
  height: 180px;
  &__title {
    font-size: 20px;
    color: #425563;
    font-weight: 500;
  }
  &__description {
    font-size: 16px;
    color: #425563;
    margin-top: 10px;
  }

  &__button {
    margin-top: 20px;
  }
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: rgb(225,255,255)!important;
}
