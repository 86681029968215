.product-mockup-step {
  margin-bottom: 50px;
  .tree-view ul {
    //max-height: 380px!important;
    max-height: unset;
  }
  .mockup-list-preview {
    //max-height: 600px!important;
    //overflow-y: auto;
  }
}
