.relative {
  position: relative;
}

.overflowHidden {
  overflow: hidden;
}

.absolute {
  position: absolute;
}

.top0 {
  top: 0;
}

.left0 {
  left: 0;
}


.bottom0 {
  bottom: 0;
}

.right0 {
  right: 0;
}

.verticalCenter {
  top: 50%;
  transform: translateY(-50%);
}

.horizontalCenter {
  left: 50%;
  transform: translateX(-50%);
}

.zIndex0 {
  z-index: 0;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex3 {
  z-index: 3;
}

