.index-cards {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  &__inner {
    display: flex;
    justify-content: center;
    background: $color-primary;
    padding: 8px;
    border-radius: 8px;
    .card {
      margin-bottom: 0;
      width: 200px;
      border-radius: unset;
      border: 0!important;
      background: unset;
      .card-body {
        background: #fff;
        border-radius: 8px;
        text-align: center;
      }
      .card-button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        //background: $color-primary;
      }
    }
    .card + .card {
      margin-left: 5px;
    }
  }
}
