.product-listings-table {
  &.product-listings-table--filterable {
    th {
      vertical-align: bottom!important;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    td {
      box-shadow: unset!important;
      border: 0 !important;
    }
    thead {
      th {
        background: transparent;
        border: 0 !important;
      }
    }
    tbody {
      border-left: 1px solid black;
      border-top: 1px solid black;
      tr {
        td {
          border-top: 1px solid transparent!important;
          border-bottom: 1px solid transparent!important;
          &:first-child {
            border-left: 1px solid transparent!important;
          }
          &:last-child {
            border-right: 1px solid transparent!important;
          }
          transition: .3s;
        }
        &:hover {
          td {
            border-top-color: #ddd!important;
            border-bottom-color: #ddd!important;
            &:first-child {
              border-left-color: #ddd!important;
            }
            &:last-child {
              border-right-color: #ddd!important;
            }
          }
        }
      }
      td {
        background: #fff;

        .badge {
          font-size: 14px;
          font-weight: 400;
          padding: 8px 15px 5px 15px;
          width: 120px;
          border-radius: 3px;
          color: #000;
          &.bg-success {
            background-color: #b9ff80!important;
          }
        }
      }
    }
  }
  &__base {
    display: flex;
    align-items: center;
    &__img {
      border: 1px solid #dddddd;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
    &__right {
      margin-left: 20px;
      &__title {
        font-size: 14px;
        font-weight: 500;
      }
      &__description {
        font-size: 12px;
      }
    }
  }

  &__titles {
    margin-bottom: 0;
    //list-style-type: disc;
    &__item {
      max-width: 340px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 10px;
      position: relative;
      &::before {
        content: '•';
        margin-right: 5px;
        color: #000;
        position: absolute;
        top: -6px;
        font-size: 22px;
        left: 0;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__buttons {
      display: flex;
      align-items: center;
    }
    button {
      background: unset;
      border: 0;
      box-shadow: unset;
      i {
        color: #b1b6ba;
        transition: .3s;
      }
      &:hover {
        cursor: pointer;
        i {
          color: #000;
        }
      }
    }
    &__menu {
      margin-left: 30px;
      i {
        font-size: 22px;
      }
    }
  }
}
