.video-guide {
  &__btn {
    transition: .3s;
    &:hover {
      background: #FF0000;
      border-color: #FF0000;
    }

    display: flex;
    align-items: center;
    i {
      margin-right: 5px;
      font-size: 25px;
      line-height: 15px;
      margin-bottom: 4px;
    }
  }
}
