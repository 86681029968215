.link-info-card {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  border: 2px solid transparent;
  box-shadow: 0px 2px 6px #0000000F;
  position: relative;
  margin-bottom: 20px;
  opacity: .8;
  transition: .3s;
  &:hover {
      opacity: 1;
  }
  &.link-info-card--new {
    border-color: #8AE8DB;
  }

  &__new {
    position: absolute;
    top: -10px;
    left: 20px;
    height: 20px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 2px;
    background: #8AE8DB;
    font-size: 10px;
    color: #FFFFFF;
  }

  &__category {
    position: absolute;
    top: -10px;
    left: 20px;
    height: 20px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 2px;
    background: #8AE8DB;
    font-size: 10px;
    color: #FFFFFF;
  }

  &__image {
    height: 110px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    &__placeholder {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: #E1E1E2;
      border-radius: 10px;
      i {
        font-size: 60px;
        color: #c7c7c7;
      }
    }
  }

  &__title {
    margin-top: 10px;
    color: #425563;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    &__name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__ext {
      display: block;
    }
  }

  &__description {
    color: #425563;
    margin-top: 10px;
  }

  &__button {
    margin-top: 20px;
  }
}
