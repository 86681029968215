.wizard-summary {
  position: relative;
  .input-group {
    min-height: 33px;
  }
  .input-group-text {
    font-size: 11px;
    width: 70px;
    justify-content: center;
    &.input-group-text--lg {
      width: 100px;
    }
  }
  &__delivery-data {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 15px;
    span {
      margin-right: 7px;
    }
  }
  &__alert {
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    color: #ccd0d4;
    text-align: center;
    padding: 16px 12px;
  }

  &__value-table {
    margin-top: 20px;
    tr {
      td {
        padding-top: 5px;
        padding-bottom: 5px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        border-color: #425563!important;
      }

      &:nth-child(2) {
        td {
          padding-bottom: 14px;
        }
      }

      &:last-child {
        td {
          padding-top: 14px;
        }
      }
    }
  }
}

.wizard-summary__table {
  width: 100%;
  &.wizard-summary__table--extended {
    .wizard-summary__table__row {
      height: 90px;
      &__price {
        margin-right: 50px;
        font-size: 14px;
      }

      &__title {
        font-size: 14px;
      }

      &__count {
        //margin-right: 145px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &__value {
          margin: 0 15px;
          font-size: 14px;
          font-weight: 600;
          line-height: 14px;
        }
        &__btn {
          border-radius: 4px;
          width: 20px;
          height: 20px;
          border: 0;
          box-shadow: unset;
          outline: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .3s;
          background: #f5f6f8;
          color: #777676;
          &:hover {
            background: $color-success;
            color: #fff;
          }
        }
      }
    }
  }
  &__row {
    transition: .3s;
    position: relative;
    //display: flex;
    //align-items: center;
    border-radius: 4px;
    background-color: #ffffff;
    height: 42px;
    //padding: 0 12px;
    margin-top: 7px;
    font-size: 12px;
    > td {
      padding: 0 12px;
    }
    &:first-child {
      margin-top: 0;
    }
    &__image {
      margin-right: 12px;
    }
    &__index {
      font-weight: 600;
      width: 10px;
    }
    &__title {
      margin-left: 15px;
      max-width: 168px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__controls {
      display: flex;
      justify-content: flex-end;
    }
    &__price {
      color: #8ae8db;
      margin-right: 4px;
      margin-top: 4px;
      white-space: nowrap;
    }
    &__control-button {
      color: #ccd0d4;
      margin-left: 9px;
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      &.wizard-summary__table__row__control-button--remove {
        //margin-top: 1px;
      }
    }
  }
  &__sub-row {
    background: #ffffff;
    table {
      width: 100%;
    }
    //padding: 15px 12px;
    .sub-row__item {
      display: flex;
      align-items: center;
      background: #eee;
      padding: 3px 12px;
      font-size: 12px;
      &__title {
        font-weight: 600;
        margin-right: 5px;
      }
      &__value {
        img {
          margin-right: 5px;
        }
      }
      &__second-value {
        margin-left: auto;
      }
    }
  }
}

.small-img {
  width: 42px;
  height: 42px;
}


.xs-img {
  width: 26px;
  height: 26px;
}
