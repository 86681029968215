.wizard-step {
  flex-grow: 1;
  padding: 15px 20px;

  &__description {
    margin-top: 20px;
    padding-right: 200px;
  }

  &__search {
    .input-group-text {
      padding-right: 0;

      i {
        margin-top: -1px;
      }
    }

    input {
      padding-left: 7px !important;
      padding-top: 10px;

      //&:focus {
      //  border-color: #ced4da !important;
      //}
    }
  }

  &__scrollable-container {
    border: 1px solid transparent;
    position: relative;
    padding: 10px;
    margin: -10px;
    border-radius: 10px;
    &__inner {
      position: relative;
      max-height: 385px;
      overflow-y: auto;
      border: 1px solid transparent;
      background: /* Shadow Cover TOP */
              linear-gradient(
                              transparent 30%,
                              rgba(255, 255, 255, 0)
              ) center top,
                /* Shadow Cover BOTTOM */
              linear-gradient(
                              rgba(255, 255, 255, 0),
                              #fcfcfc 70%
              ) center bottom,
                /* Shadow TOP */
              linear-gradient(
                              transparent,
                              rgba(0, 0, 0, 0)
              ) center top,
                /* Shadow BOTTOM */
              linear-gradient(
                              rgba(0, 0, 0, 0),
                              rgba(255,255,255,1)
              ) center bottom;

      background-repeat: no-repeat;
      background-size: 100% 50px, 100% 50px, 100% 40px, 100% 40px;
      background-attachment: local, local, scroll, scroll;
    }

    &.wizard-step__scrollable-container--invalid {
      border-color: #f56e50;
    }

    &.wizard-step__scrollable-container--wmx {
      .wizard-step__scrollable-container__inner {
        max-height: unset;
      }
    }

    &__tooltip {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #f56e50;
    }
  }

  &__error-container {
    border: 1px solid transparent;
    position: relative;
    padding: 10px;
    margin: -10px;
    border-radius: 10px;
    &__inner {
      position: relative;
      border: 1px solid transparent;
    }

    &.wizard-step__error-container--invalid {
      border-color: #f56e50;
    }

    &__tooltip {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #f56e50;
    }
  }

  .column {
    &.column--primary {
      padding-right: 60px;
    }

    &.column--secondary {
      padding-left: 40px;
      border-left: 1px solid #e1e1e2;
    }
  }
}

.wizard-step-controls {
  position: fixed;
  bottom: 10px;
  display: flex;
  align-items: center;
  height: 70px;
  background: #fff;
  z-index: 100;
  .btn {
    margin-bottom: 0 !important;
  }

  &.wizard-step-controls--padding-right {
    padding-right: 70px;
  }

  .wizard-step-controls__btn-fixed {
    position: fixed;
    right: 100px;
  }
}
