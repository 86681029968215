.wizard-steps {
  //display: flex;
  //align-items: center;
  width: 100%;
  //background: #f1f1f1;
  padding: 10px 0;
  &.wizard-steps--white {
    .steps {
      ul {
        li {
          a {
            background: #fff;
          }
        }
      }
    }
  }
  .steps {
    width: 100%;
    display: flex;
    //justify-content: center;
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -15px;
      padding: 0;
      li {
        position: relative;
        margin: 15px;
        a {
          background: #fcfcfc;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: 700;
          @media(max-width: 1300px) {
            font-size: 16px;
          }
          .number {
            width: 45px;
            height: 45px;
            min-width: 45px;
            border-radius: 50%;
            //border: 1px solid $color-primary;
            padding-top: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .title {
            margin-left: 12px;
          }
        }

        &.disabled {
          a {
            font-size: 14px;
            font-weight: 400;
            .number {
              background: #f5f6f8;
              color: $gray-400;
              width: 35px;
              height: 35px;
              min-width: 35px;
            }
            span {
              color: $gray-400;
            }
          }
        }

        &.current {
          padding-right: 12px;
          a {
            .number {
              background: #ffcd8b;
              color: #fff;
              width: 45px;
              height: 45px;
            }
            span {
              color: #ffcd8b;
            }
          }
          &:not(:last-child) {
            margin-right: 130px;
            &:before {
              content: "";
              position: absolute;
              width: 130px;
              right: -130px;
              top: 21px;
              background-image: url("../../../assets/images/dots.svg");
              background-position: left center;
              background-size: 125% 125%;
              background-repeat: repeat-x;
              height: 2px;
              @media(max-width: 1300px) {
                display: none;
              }
            }
          }
          @media(max-width: 1300px) {
            margin-right: 0;
          }
        }
        &.passed {
          a {
            .number {
              background: #8ae8db;
              color: #fff;
              width: 45px;
              height: 45px;
              i {
                font-size: 28px;
                margin-top: -5px;
              }
            }
            span {
              color: #8ae8db;
            }
          }
        }
        //&:first-child {
        //  margin-left: 0;
        //}
      }

      //li + li {
      //  &:before {
      //    content: "";
      //    position: absolute;
      //    width: 100px;
      //    left: -100px;
      //    top: 17px;
      //    border-top: 2px dotted #dfdfdf;
      //    height: 2px;
      //  }
      //}
    }
  }

  &__video {
    margin-left: auto;
  }
}
