.message-item {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 2px 6px #0000000F;
  border: 2px solid transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &.message-item--new {
    border-color: #8AE8DB;
  }

  //&.message-item--read {
  //  opacity: 0.7;
  //  transition: .3s;
  //
  //  &:hover {
  //    opacity: 1;
  //  }
  //}



  &.message-item--has-image {
    .message-item__description {
      display: -webkit-box;
      //max-width: 400px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 20px;
    }

    &.message-item--full {
      .message-item__body__content {
        margin-left: 30px;
      }
    }
  }

  &.message-item--full {
    cursor: default;
    .message-item__header {

      &__read-state {
        margin-left: 50px;
      }

      &__timestamp {
        font-size: 14px;
      }
    }

    .message-item__body {
      flex-direction: row;
      &__content {
        order: 2;
      }
      .message-item__image {
        order: 1;
        height: unset;
        max-height: 200px;
        min-width: 230px;
        margin-top: 0;
        img {
          object-fit: cover;
        }
      }
    }
    .message-item__description {
      max-height: unset;
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      display: flex!important;
      flex-direction: column;

      a {
        text-decoration: underline!important;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &__timestamp {
      color: #8AE8DB;
      font-size: 12px;
    }
    &__read-state {
      margin-left: auto;
      font-size: 12px;
      color: #E1E1E2;
      button {
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
        color: #E1E1E2;
      }
    }

    &__new {
      position: absolute;
      top: -10px;
      left: 20px;
      height: 20px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      border-radius: 2px;
      background: #8AE8DB;
      font-size: 10px;
      color: #FFFFFF;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__description {
    font-size: 14px;
    color: #425563;
    margin-bottom: 0;

    max-height: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      color: #425563!important;
    }
  }

  &__image {
    margin-top: auto;
    height: 110px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}
