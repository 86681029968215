.radio-select {
  &__item {
    //height: 60px;
    display: flex;
    align-items: center;
    padding: 17px 20px;
    margin-bottom: 10px;
    border: 1px solid #e1e1e2;
    background-color: #ffffff;
    border-radius: 4px;
    &__state {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      border-radius: 50%;
      border: 1px solid #e1e1e2;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    &__price {
      font-weight: 600;
      margin-right: 10px;
    }
    &__image {
      margin-right: 5px;
      img {
        width: 50px;
      }
    }
    &.radio-select__item--selected {
      border-color: #425563;
      .radio-select__item__state {
        border-color: #8ae8db;
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #8ae8db;
        }
      }
    }
  }
}
