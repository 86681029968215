.mockup-order-setup {
  &__entity {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d9d9d9;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    //height: 100%;
    height: 150px;

    &__title {
      text-align: center;
      font-size: 13px;
      margin-top: 5px;
    }

    &.mockup-order-setup__entity--primary {
      max-height: unset;
      height: calc(100% - 20px);
    }

    &__order {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #425563;
      color: #fff;
      padding: 3px 10px;
      border-radius: 15px;
    }

    &__hover {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: .3s;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      &.active {
        visibility: visible;
        opacity: 1;
      }
      span {
        background: #425563;
        color: #fff;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
      }
    }

    &:hover {
      .mockup-order-setup__entity__hover {
        visibility: visible;
        opacity: 1;
      }
    }

    &__image {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
