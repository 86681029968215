.file-upload {
  &.file-upload--small {
    .dropzone {
      min-height: 150px;
      .dz-message {
        padding: 15px;
      }
    }
  }

  &.is-invalid {
    //label {
    //  color: #f56e50;
    //}
    .invalid-feedback {
      margin-top: -10px;
      margin-bottom: 15px;
    }
    .dropzone {
      border-color: #f56e50;
    }
  }
}
