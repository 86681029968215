//
// _footer.scss
//

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    color: $footer-color;
    left: $sidebar-width;
    height: $footer-height;
    background-color: $footer-bg;
    //margin-top: -$footer-height;

    @media (max-width: 991.98px) {
        left: 0;
    }
}

.sidebar-footer {
    margin-top: auto;
    padding: 20px;
    color: #bdd6e4;
    font-size: 12px;
    font-weight: 400;
    background: #425563;
}

// Enlarge menu
body[data-sidebar-size="sm"] {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }
}
