.setup-pending-orders {
  &__table {
    width: 100%;
    background-color: #eee;
    &__price {
      text-align: center;
      background-color: #ffffff;
      width: 80px;
    }
    thead {
      th {
        white-space: nowrap;
      }
    }
    td,th {
      padding: 5px;
    }
    th {
      padding-bottom: 0;
    }
    &__row {
      img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }
    }
    &__select {
      min-width: 170px;
      max-width: 170px;
      .select-field {
        width: 100%;
        margin-bottom: 0!important;
      }
    }
  }
  &__heading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .select-field {
      margin-left: auto;
      margin-bottom: 0!important;
    }
  }

  &__country-group {
    background: #fafafa;
    padding: 15px;
  }

  .setup-pending-orders__country-group + .setup-pending-orders__country-group {
    margin-top: 20px;
  }
}
